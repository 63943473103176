import { inject }        from 'aurelia-framework';
import { BaseViewModel } from 'base-view-model';
import { AppContainer }  from 'resources/services/app-container';

@inject(AppContainer)
export class ActionIconCustomListingCell extends BaseViewModel {

    constructor(appContainer) {
        super(appContainer);
    }

    activate(model) {
        this.row    = model.row;
        this.column = model.column;

        // If column.show is function, execute it, otherwise try to get direct value...
        // If undefined, set it to true by default
        this.show = this.column.show instanceof Function
            ? this.column.show(this.row)
            : (this.column.show ?? true);
    }

    /**
     * Handles custom action button
     *
     * @param action
     * @param row
     *
     * @returns {*}
     */
    doCustomAction(action, row) {
        if (action instanceof Function) {
            return action(row);
        }
    }
}
