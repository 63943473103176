export class checkmarkCustomListingCell {
    /**
     * Handles activate event
     *
     * @param model
     */
    activate({ column, row }) {
        const checkedValueKey = column.checkedRowValue ?? column.data;
        this.checked          = !!row[checkedValueKey];

        if (!column.fullDataModel) {
            console.error('Please provide fullDataModel for checkmark column');
        } else {
            this.checkmarkModel = row[column.fullDataModel];
        }
    }
}
