import { Comment } from 'modules/core/models/comment';

export class CommentFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {Comment}
     */
    model() {
        let model = new Comment();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.text = {
            type:       'textarea',
            key:        'text',
            label:      'form.field.comment',
            size:       12,
            required:   false,
            attributes: {
                readonly: readonly,
            },
        };

        this.created_by = {
            type:       'text',
            key:        'created_by',
            label:      'form.field.created-by',
            size:       12,
            attributes: {
                readonly: true,
            },
        };

        this.created_at = {
            type:       'text',
            key:        'created_at',
            label:      'form.field.created-at',
            size:       12,
            attributes: {
                readonly: true,
            },
        };

        const schema = [
            [this.text],
        ];

        if (readonly) {

            this.created_by = {
                type:       'text',
                key:        'created_by',
                label:      'form.field.created-by',
                size:       12,
                attributes: {
                    readonly: true,
                },
            };

            this.created_at = {
                type:       'text',
                key:        'created_at',
                label:      'form.field.created-at',
                size:       12,
                attributes: {
                    readonly: true,
                },
            };

            return [...schema, [this.created_by, this.created_at]];
        }

        return schema;
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
