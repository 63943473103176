import { AuthService }               from 'aurelia-auth';
import { Aurelia, bindable, inject } from 'aurelia-framework';
import { PLATFORM }                  from 'aurelia-pal';
import { BaseFormViewModel }         from 'base-form-view-model';
import { ConsentsRepository }        from 'modules/administration/consents/services/repository';
import { FormSchema }                from 'modules/core/account-settings/form-schema';
import { AccountSettingsRepository } from 'modules/core/account-settings/services/repository';
import { AureliaAuthConfig }         from 'resources/configs/aurelia-auth-config';
import { AppContainer }              from 'resources/services/app-container';

@inject(Aurelia, AppContainer, AuthService, FormSchema, AccountSettingsRepository, ConsentsRepository)
export class EditAccountSettings extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.account-settings';
    @bindable formId      = 'core-account-settings-form';

    alert  = {};
    model  = {};
    schema = {};

    @bindable checkedConsents = [];

    /**
     * Constructor
     *
     * @param aurelia
     * @param appContainer
     * @param authService
     * @param formSchema
     * @param accountSettingsRepository
     * @param consentsRepository
     */
    constructor(aurelia, appContainer, authService, formSchema, accountSettingsRepository, consentsRepository) {
        super(appContainer);

        this.aurelia                   = aurelia;
        this.authService               = authService;
        this.formSchema                = formSchema;
        this.accountSettingsRepository = accountSettingsRepository;
        this.consentsRepository        = consentsRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/core/account-settings/index.html');
    }

    /**
     * Handles activate event
     *
     * @returns {Promise.<*>|Promise}
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.infoSchema           = this.formSchema.infoSchema(this);
            this.changePasswordSchema = this.formSchema.changePasswordSchema(this);
        });
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        $('.form-check-input-styled').uniform();
    }

    /**
     * Fetches data from server
     *
     * @param params
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData(params) {
        return Promise.all([
            this.consentsRepository.active().then((consents) => this.consents = consents),
            this.accountSettingsRepository.find().then((accountSettings) => {
                this.initialModel.assign(accountSettings);
                this.model.assign(accountSettings);
                this.checkedConsents.splice(0, this.checkedConsents.length, ...accountSettings.consents);
            }),
        ]);
    }

    /**
     * Updates the user consents
     */
    updateConsents() {
        this.accountSettingsRepository
            .updateConsents({ consents: this.checkedConsents })
            .then((response) => {
                if (response.status === true) {
                    this.appContainer.notifier.successNotice(response.message);

                    if (this.checkedConsents.length !== this.consents.length) {
                        this.authService.logout(AureliaAuthConfig.logoutRedirect)
                            .then(() => this.aurelia.setRoot(PLATFORM.moduleName('app-offline')))
                            .catch(() => console.error('Error logging out'));
                    }
                } else {
                    this.alert = this.alertMessage(response.status, response.message, response.errors);
                }
            });
    }

    /**
     * Changes the user password
     *
     * @return {Promise.<*>|Promise}
     */
    changePassword() {
        this.alert = null;

        return this.accountSettingsRepository.changePassword({
            current_password:      this.model.current_password,
            password:              this.model.password,
            password_confirmation: this.model.password_confirmation,
        }).then((response) => {
            if (response.status === true) {
                this.appContainer.notifier.successNotice(response.message);
                this.resetForm();
            } else {
                this.alert = this.alertMessage(response.status, response.message, response.errors);
            }
        });
    }
}
