import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AccountSettingsRepository {

    baseUrl = 'account-settings';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Finds a record
     *
     * @returns {*}
     */
    find() {
        return this.httpClient.get(`${this.baseUrl}`);
    }

    /**
     * Changes the user password
     *
     * @param data
     *
     * @returns {*}
     */
    changePassword(data) {
        return this.httpClient.patch(`${this.baseUrl}/change-password`, data);
    }

    /**
     * Updates the user consents
     *
     * @param data
     *
     * @returns {*}
     */
    updateConsents(data) {
        return this.httpClient.patch(`${this.baseUrl}/consents`, data);
    }

}
