import { ProcessStatus } from 'modules/core/models/process-status';

export class ProcessingStatusListingCell {

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.model  = model;
        this.column = this.model.column;
        this.row    = this.model.row;

        if (this.model.row[this.column.data]) {
            this.icon  = this._getIconByStatus(this.model.row[this.column.data].id);
            this.title = this.model.row[this.column.data].message;
        }
    }

    /**
     * gets the icon by status
     *
     * @param statusId
     * @return {string}
     */
    _getIconByStatus(statusId) {
        switch (statusId) {
            case ProcessStatus.NOT_STARTED:
                return 'icon-file-empty2 text-muted';
            case ProcessStatus.FINISHED:
                return 'icon-file-check2 text-success';
            case ProcessStatus.INVALID || ProcessStatus.CANCELED:
                return 'icon-file-minus2 text-danger';
            case ProcessStatus.IN_PROGRESS_READING:
                return 'icon-file-stats2 text-warning';
            default:
                return 'icon-file-presentation2 text-blue';
        }
    }

    /**
     * Handles custom action button
     *
     * @param action
     * @param row
     *
     * @returns {*}
     */
    doCustomAction(action, row) {
        if (action instanceof Function) {
            return action(row);
        }
    }
}
