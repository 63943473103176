import { inject }        from 'aurelia-framework';
import { BaseViewModel } from 'base-view-model';
import { AppContainer }  from 'resources/services/app-container';

@inject(AppContainer)
export class VerifiedCustomListingCell extends BaseViewModel {

    constructor(appContainer) {
        super(appContainer);
    }

    canActivate() {
        return true;
    }

    activate(model) {
        this.column = model.column;
        const row   = model.row;

        this.isVerifiedInspection    = row.verified_inspection_by !== null && row.verified_inspection_at !== null;
        this.isVerifiedConcessionary = row.verified_concessionary_by !== null && row.verified_concessionary_at !== null;

        this.inspectionVerifier = (
            (typeof row.verified_inspection_by === 'object' && row.verified_inspection_by !== null)
                ? `${row.verified_inspection_by.identifier} - ${row.verified_inspection_by.user.display_name} (${row.verified_inspection_by.entity.name}) - `
                : ''
        ) + this.appContainer.i18n.tr('form.field.regulator');

        this.concessionaryVerifier = (
            (typeof row.verified_concessionary_by === 'object' && row.verified_concessionary_by !== null)
                ? `${row.verified_concessionary_by.identifier} - ${row.verified_concessionary_by.user.display_name} (${row.verified_concessionary_by.entity.name}) - `
                : ''
        ) + this.appContainer.i18n.tr('form.field.highway-operator');
    }
}
