import { AccountSettings } from 'modules/core//models/account-settings';

export class FormSchema {
    /**
     * Returns a new instance of the model
     *
     * @returns {AccountSettings}
     */
    model() {
        return new AccountSettings();
    }

    /**
     * Returns `user info` form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    infoSchema(viewModel) {
        this.name = {
            type:       'text',
            key:        'name',
            label:      'form.field.name',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.email = {
            type:       'text',
            key:        'email',
            label:      'form.field.email',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.name, this.email],
        ];
    }

    /**
     * Returns `change password` form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    changePasswordSchema(viewModel) {
        this.current_password = {
            type:  'password',
            key:   'current_password',
            label: 'form.field.current-password',
            size:  4,
        };

        this.password = {
            type:  'password',
            key:   'password',
            label: 'form.field.password',
            size:  4,
        };

        this.password_confirmation = {
            type:  'password',
            key:   'password_confirmation',
            label: 'form.field.password-confirmation',
            size:  4,
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.changePassword(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.submitButton,
            ],
        };

        return [
            [this.current_password, this.password, this.password_confirmation],
            [this.buttons],
        ];
    }
}
